<template>
  <div class="setting_form clear">
    <utm-modal></utm-modal>

    <div class="white_box  ">
      <div class="box_inner">
        <div class="box_head ">
          <div class="d-flex align-items-center">
            <div class="left">
              <h2>UTM Tracking</h2>
            </div>
            <div class="right ml-auto">
              <guided-tour v-if="getGuidedTour.step10"></guided-tour>


              <button @click.prevent="addUTM()"
                      class="btn---cta btn-blue with-shadow btn-round   btn-bold">
                <span>Add UTM Preset</span>
                <span class="icon_right">
                               <i
                                 class="circle_icon d-flex align-items-center justify-content-center  fal fa-plus  "></i>
                            </span>
              </button>

            </div>
          </div>

          <div class="left mt-3">
            <p>With UTM parameters attached to your links, you will have the ability to track your campaign's
              performance in Google Analytics.</p>
            <p>This will also allow you to see how much traffic are you receiving from the links you shared on
              different social channels.
            </p>
          </div>
          <div class="d-flex align-items-center mt-2">
            <div class="left">
              <div class="mr-2 search_input_block no_animation" :class="{ active_input: payload.search }">
                <input placeholder="Search UTMS" :value="payload.search" @input="debounceSearch" type="text"
                       class="search_input"/>
                <i class="fal fa-search"></i>
              </div>
            </div>
            <div class="right ml-auto">
              <b-dropdown
                class="ml-2 d-inline-block dropdown-menu-right default_style_dropdown checkbox_dropdown results_dropdown"
                :no-caret="true"
                ref="resultsDropdown">
                <div class="dropdown_header rounded_shape d-flex align-items-center  "
                     slot="button-content">
                  <span class="text">{{ resultsText }}</span>
                  <span class="arrow_icon ml-auto">
                                                <i class=" fal fa-angle-down ml-3"></i>
                                             </span>
                </div>
                <ul class="inner results_filter_dropdown">
                  <li :key="index" v-for="(item,index) in [10, 20, 30, 50, 100]" class="list_item_li"
                      @click="results(item)">
                    {{ item }} Results
                    <i v-if="payload.limit==item" class="far fa-check"></i>
                  </li>
                </ul>
              </b-dropdown>
            </div>
          </div>
        </div>
        <div class="box_content">

          <div class="table_container">
            <table class="table campaign_box list-content">

              <thead class=" ">
              <tr>
                <th class="" style="width: 230px;">UTM Campaign</th>
                <th class="" style="width: 230px;">UTM Medium</th>
                <th class="" style="width: 200px;">UTM Source</th>
                <th class="" style="width: 200px;">UTM Term</th>
                <th class="" style="width: 200px;">UTM Content</th>
                <th style="width: 200px;text-align: right;">Actions</th>
              </tr>
              </thead>

              <tbody class="list-content-body">
              <tr v-if="getUTMRetrieveLoader">
                <td colspan="7">
                  <clip-loader
                    :size="'20px'"
                    :color="color"
                  ></clip-loader>
                </td>
              </tr>
              <tr v-for="(utm, index) in getUTMList" v-else-if="getUTMList && getUTMList.length > 0">
                <td class="truncate-td" style="text-align: left;">{{ utm.campaign }}</td>
                <td class="truncate-td">{{ utm.medium }}</td>
                <td class="truncate-td">{{ utm.source }}</td>
                <td class="truncate-td">{{ utm.term }}</td>
                <td class="truncate-td" v-tooltip="utm.content">{{ utm.content }}</td>
                <td class="table-action ">
                  <i v-tooltip="'Edit UTM'" @click="editUTM(utm)" class="far fa-pencil "
                     aria-hidden="true"></i>
                  <i v-tooltip="'Remove UTM'" @click="isRemoveUTM(utm, index)"
                     class="far fa-trash "
                     aria-hidden="true"></i>
                </td>
              </tr>
              <tr v-else>
                <td colspan="9">
                  <p class="no-rows"><i class="fab fa-bandcamp" aria-hidden="true"></i>{{getFilteredMessage('UTM preset',payload.search, 'All Time', payload.archive) }}</p>
                </td>
              </tr>


              </tbody>

            </table>
          </div>
        </div>


      </div>
      <template v-if="pagination.totalItems > pagination.limit">
        <pagination
          :clickedMethod="paginateUTMS"
          :resultCount="pagination.totalItems"
          :itemsPerPage="pagination.limit"
          :currentPage="pagination.page"
          :totalPagesNumber="pagination.totalPages"
        ></pagination>
      </template>
    </div>


  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import UTMModal from './UTMModal'
import { utmTypes } from '@/state/modules/mutation-types'
import { DARK_PURPLE_LOADER_COLOR } from '@/common/constants'

export default ({

  data () {
    return {
      size: '14px',
      color: DARK_PURPLE_LOADER_COLOR,
      colorWhite: '#ffffff',
      resultsText: 'Results Per Page',
      payload: {
        search: '',
        page: 1,
        limit: 10,
        sort: 'created_desc'
      },
      pagination: {}
    }
  },
  components: {
    'utm-modal': UTMModal
  },
  async created () {
    this.getSiteDetails
    const params = this.getParamsFromURL()
    this.payload.search = params.search
    this.payload.page = params.page
    this.payload.limit = params.limit
    if (this.payload.search && this.payload.search.length > 0) this.payload.page = 1
    if (this.payload.limit && this.payload.limit > 10) {
      this.resultsText = this.payload.limit + ' Results'
      this.payload.page = 1
    }
    const pagination = await this.fetchUTMList(this.payload)
    this.setUTMPagination(pagination)

  },
  methods: {
    ...mapActions(['removeUTM','fetchUTMList']),
    editUTM (payload) {
      this.$store.commit(utmTypes.EDIT_CREATE_UTM, payload)
      this.$bvModal.show('addUtmModal')
    },
    addUTM () {
      this.$store.commit(utmTypes.RESET_CREATE_UTM)
      this.$bvModal.show('addUtmModal')
    },
    isRemoveUTM (payload, index) {
      let self = this
      swal({
          title: 'Are you sure ?',
          type: 'warning',
          text: '',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, Delete it!',
          cancelButtonText: 'No, Cancel!',
          closeOnConfirm: true,
          customClass: 'sweetAlert_box',
          closeOnCancel: true,
          animation: false
        },
        function (isConfirm) {
          if (isConfirm) {
            self.removeUTM({ utmId: payload._id, index: index })
          } else {
            swal()
          }
        })
    },
    debounceSearch (event) {
      this.$router.replace({ query: { ...this.$route.query, search: event.target.value } })
      this.$router.replace({ query: { ...this.$route.query, page: 1 } })
      this.payload.search = event.target.value
      this.payload.page = 1
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        const pagination = await this.fetchUTMList(this.payload)
        this.setUTMPagination(pagination)
      }, 600)
    },
    setUTMPagination (pagination) {
      this.pagination = {
        page: pagination.current_page,
        totalItems: pagination.total,
        limit: pagination.per_page,
        totalPages: pagination.last_page
      }
    },
    async paginateUTMS (page) {
      this.$router.replace({ query: { ...this.$route.query, page: page } })
      this.payload.page = page
      this.pagination.page = page
      const pagination = await this.fetchUTMList(this.payload)
      this.setUTMPagination(pagination)
    },
    async results (value) {
      this.resultsText = value + ' Results'
      this.$refs.resultsDropdown.hide()
      this.$router.replace({ query: { ...this.$route.query, limit: value } })
      this.$router.replace({ query: { ...this.$route.query, page: 1 } })
      this.payload.limit = value
      this.payload.page = 1
      const pagination = await this.fetchUTMList(this.payload)
      this.setUTMPagination(pagination)
    },
  },
  computed: {
    ...mapGetters(['getUTMList','getUTMRetrieveLoader'])
  }

})
</script>

<style lang="less" scoped>
.truncate-td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1px;
}
</style>
