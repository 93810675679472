<template>


  <b-modal modal-class="modal_basic addUtmModal" id="addUtmModal" hide-footer hide-header>
    <img @click="$bvModal.hide('addUtmModal')" class="close_modal" src="/assets/img/cross.svg" alt="">


    <div class="basic_form modal_content" v-on-clickaway="close">

      <div class="heading">
        <h3>
          <template v-if="getCreateUTM._id">
            Update your UTM Preset
          </template>
          <template v-else>
            Create your UTM Preset
          </template>
        </h3>
      </div>


      <div class="basic_form clear">

        <div class="d-flex align-items-center  ">
          <div class="col-md-6 pl-0 pr-1">
            <div :class="{'input-field-error' : validations.campaign || validations.campaignLength}"
                 class="input_icon_text d-flex align-items-center profile__box">
              <div class="content">
                <div class="url">UTM Campaign</div>
                <div class="name">
                  <div class="validation_input">
                    <input
                      v-model="getCreateUTM.campaign"
                      type="text"
                      placeholder="Product, promo code, or slogan (e.g. spring_sale)" data-cy="u-camp">
                  </div>
                </div>
              </div>
            </div>
            <span class="input-error" v-if="validations.campaign">{{ messages.campaign }}</span>
            <span class="input-error" v-else-if="validations.campaignLength">{{ messages.campaignLength }}</span>
          </div>

          <div class="col-md-6 pr-0 pl-1">
            <div :class="{'input-field-error' : validations.medium || validations.mediumLength}"
                 class="input_icon_text d-flex align-items-center profile__box">
              <div class="content">
                <div class="url">UTM Medium</div>
                <div class="name">
                  <div class="validation_input">
                    <input v-model="getCreateUTM.medium"
                           type="text"
                           placeholder="Marketing Medium (e.g. social)" data-cy="u-med">
                  </div>
                </div>
              </div>
            </div>
            <span class="input-error" v-if="validations.medium">{{ messages.medium }}</span>
            <span class="input-error" v-else-if="validations.mediumLength">{{ messages.mediumLength }}</span>
          </div>


        </div>
        <div class="  d-flex align-items-center">

          <div class="col-md-6 pl-0 pr-1">
            <div :class="{'input-field-error' : validations.source || validations.sourceLength}"
                 class="mt-3 input_icon_text d-flex align-items-center profile__box">
              <div class="content">
                <div class="url">UTM Source</div>
                <div class="name">
                  <div class="validation_input">
                    <input v-model="getCreateUTM.source"
                           type="text"
                           placeholder="The referrer (e.g. facebook, twitter)" data-cy="u-src">
                  </div>
                </div>
              </div>
            </div>
            <span class="input-error" v-if="validations.source">{{ messages.source }}</span>
            <span class="input-error" v-else-if="validations.sourceLength">{{ messages.sourceLength }}</span>
          </div>
          <div class="col-md-6 pr-0 pl-1">
            <div :class="{'input-field-error' : validations.termLength}"
                 class="mt-3  input_icon_text d-flex align-items-center profile__box">
              <div class="content">
                <div class="url">UTM Term</div>
                <div class="name">
                  <div class="validation_input">
                    <input v-model="getCreateUTM.term"
                           type="text"
                           placeholder="e.g paid keywords" data-cy="u-term">
                  </div>
                </div>
              </div>
            </div>
            <span class="input-error" v-if="validations.termLength">{{ messages.termLength }}</span>
          </div>
        </div>
        <div :class="{'input-field-error' : validations.contentLength}"
             class=" mt-3  input_icon_text d-flex align-items-center profile__box">
          <div class="content">
            <div class="url">UTM Content</div>
            <div class="name">
              <div class="validation_input">
                <input v-model="getCreateUTM.content"
                       type="text"
                       placeholder="e.g text AD name" data-cy="u-cont">
              </div>
            </div>
          </div>
        </div>
        <span class="input-error" v-if="validations.contentLength">{{ messages.contentLength }}</span>
      </div>


      <div class=" btn_block text-left">

        <button @click.prevent="saveUTM()"
                :disabled="loaders.save"

                class="btn---cta btn-blue with-shadow btn-round  btn--loader white--loader loader--right btn-bold">
          <span>Save</span>
          <clip-loader v-if="loaders.save" :size="size" :color="color"></clip-loader>
        </button>
        <button type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold" @click="close()"><span>Close</span>
        </button>
      </div>


    </div>
  </b-modal>

</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      size: '14px',
      color: '#fff',
      loaders: {
        save: false
      },
      validations: {
        name: false,
        nameLength: false,
        medium: false,
        mediumLength: false,
        source: false,
        sourceLength: false,
        campaign: false,
        campaignLength: false,
        contentLength: false,
        termLength: false
      },
      messages: {
        name: 'UTM Name is required.',
        nameLength: 'The name should not be greater than 35 characters.',
        medium: 'Medium Name is required.',
        mediumLength: 'The medium should not be greater than 100 characters.',
        source: 'Source name is required.',
        sourceLength: 'The source should not be greater than 100 characters.',
        campaign: 'Campaign name is required.',
        campaignLength: 'The campaign should not be greater than 100 characters.',
        contentLength: 'The content should not be greater than 2000 characters.',
        termLength: 'The term should not be greater than 2000 characters.'
      }
    }
  },
  computed: {
    ...mapGetters(['getCreateUTM'])
  },
  methods: {
    ...mapActions(['storeUTM']),
    async saveUTM () {
      // this.validations.name = this.requiredCheck(this.getCreateUTM.name)
      this.validations.medium = this.requiredCheck(this.getCreateUTM.medium)
      this.validations.source = this.requiredCheck(this.getCreateUTM.source)
      this.validations.campaign = this.requiredCheck(this.getCreateUTM.campaign)
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        this.loaders.save = true
        await this.storeUTM()
        this.loaders.save = false
        this.$bvModal.hide('addUtmModal')
      }
    },
    close () {
      this.$bvModal.hide('addUtmModal')
      this.validations = {
        name: false,
        nameLength: false,
        medium: false,
        mediumLength: false,
        source: false,
        sourceLength: false,
        campaign: false,
        campaignLength: false,
        contentLength: false,
        termLength: false
      }
    }
  },
  watch: {
    'getCreateUTM.name' (value) {
      if (value && value.length > 0) {
        this.validations.name = false
        this.validations.nameLength = !this.maxLength(value, 35)
      }
    },
    'getCreateUTM.medium' (value) {
      if (value && value.length > 0) {
        this.validations.medium = false
        this.validations.mediumLength = !this.maxLength(value, 100)
      }
    },
    'getCreateUTM.source' (value) {
      if (value && value.length > 0) {
        this.validations.source = false
        this.validations.sourceLength = !this.maxLength(value, 100)
      }
    },
    'getCreateUTM.campaign' (value) {
      if (value && value.length > 0) {
        this.validations.campaign = false
        this.validations.campaignLength = !this.maxLength(value, 100)
      }
    },
    'getCreateUTM.term' (value) {
      if (value && value.length > 0) {
        this.validations.termLength = !this.maxLength(value, 2000)
      }
    },
    'getCreateUTM.content' (value) {
      if (value && value.length > 0) {
        this.validations.contentLength = !this.maxLength(value, 2000)
      }
    }
  }
}
</script>
